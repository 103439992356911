<template>
  <div class="w-100 d-flex flex-column">
    <base-tool-bar title="Informations" subtitle="Informations générales"></base-tool-bar>
    <base-form
      class="mr-md-3"
      :loading="loading"
      :deleteBtn="true"
      @click="updateClient"
      @delete="deleteClient"
      shadow
    >
      <base-form-group title="Client" dense icon="briefcase">
        <base-form-row row>
          <base-select-modal
            ref="comptesmanagerselect"
            name="comptesmanager"
            title="Gestion des comptes client"
            placeholder="Compte"
            empty="Sélectionner un compte"
            :data="client_comptes"
            :description="compteDescription"
            :feedback="feedback.client_compte_id"
          >
            <comptes-manager @select="selectCompte"></comptes-manager>
          </base-select-modal>
          <base-select
            inputText="Type"
            v-model.number="client_type_id"
            :options="data.types"
            :errors="feedback.client_type_id"
          ></base-select>
        </base-form-row>
        <base-form-row row>
          <base-select
            inputText="Site"
            v-model.number="client_site_id"
            :options="data.sites"
            :errors="feedback.client_site_id"
          ></base-select>
          <base-select
            inputText="Région"
            v-model.number="client_region_id"
            :options="data.regions"
            :errors="feedback.client_region_id"
          ></base-select>
        </base-form-row>
        <base-form-row row>
          <base-input v-model="client_nom" inputText="Nom" :errors="feedback.client_nom"></base-input>
          <base-input v-model="client_prenom" inputText="Prénom" :errors="feedback.client_prenom"></base-input>
        </base-form-row>
        <base-form-row row>
          <base-input v-model="client_poste" inputText="Poste" :errors="feedback.client_poste"></base-input>
          <base-input
            v-model="client_service"
            inputText="Service"
            :errors="feedback.client_service"
          ></base-input>
        </base-form-row>
        <base-form-row row>
          <base-input-area v-model="client_commentaire" text="Infos"></base-input-area>
        </base-form-row>
      </base-form-group>
    </base-form>
  </div>
</template>
<script>
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import BaseInput from "@/components/bases/Input.vue";
import BaseSelect from "@/components/bases/Select.vue";
import BaseSelectModal from "@/components/bases/SelectModal.vue";
import BaseInputArea from "@/components/bases/InputArea.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormGroup from "@/components/bases/FormGroup.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseToolBar from "@/components/bases/ToolBar.vue";
import ComptesManager from "@/components/comptes/Manager.vue";

export default {
  components: {
    BaseInput,
    BaseSelect,
    BaseSelectModal,
    BaseInputArea,
    BaseForm,
    BaseFormGroup,
    BaseFormRow,
    BaseToolBar,
    ComptesManager
  },
  data() {
    return {
      loading: false,
      lists: {},
      feedback: {}
    };
  },
  props: {
    data: {
      Type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions({
      update: "clients/update",
      delete: "clients/delete"
    }),
    updateClient: function() {
      let data = {
        id: this.id,
        client_compte_id: this.client_compte_id,
        client_nom: this.client_nom,
        client_prenom: this.client_prenom,
        client_type_id: this.client_type_id,
        client_region_id: this.client_region_id,
        client_site_id: this.client_site_id,
        client_poste: this.client_poste,
        client_service: this.client_service,
        client_adresse: this.client_adresse,
        client_mail1: this.client_mail1,
        client_mail2: this.client_mail2,
        client_mail3: this.client_mail3,
        client_tel1: this.client_tel1,
        client_tel2: this.client_tel2,
        client_tel3: this.client_tel3,
        client_tel_fax: this.client_tel_fax,
        client_commentaire: this.client_commentaire
      };

      this.loading = true;
      this.feedback = {};
      this.update(data)
        .catch(error => this.errHandler(error))
        .finally(() => (this.loading = false));
    },
    deleteClient: function() {
      this.loading = true;
      this.feedback = {};
      this.delete()
        .then(() => this.$router.push("/clients"))
        .catch(error => this.errHandler(error))
        .finally(() => (this.loading = false));
    },
    errHandler: function(error) {
      if (error.response.status === 422) {
        this.feedback = error.response.data.error;
        return;
      }
    },
    selectCompte: function(compte) {
      this.$store.commit("clients/setCompte", compte);
      this.$refs.comptesmanagerselect.hideModal();
    },
    selectClient: function(client) {
      this.$store.commit("clients/setClient", client);
      this.$refs.clientsmanagerselect.hideModal();
    }
  },
  computed: {
    ...mapFields("clients", [
      "client.id",
      "client.client_type_id",
      "client.client_compte_id",
      "client.client_site_id",
      "client.client_region_id",
      "client.client_pilote_id",
      "client.client_nom",
      "client.client_prenom",
      "client.client_poste",
      "client.client_service",
      "client.client_adresse",
      "client.client_mail1",
      "client.client_mail2",
      "client.client_mail3",
      "client.client_tel1",
      "client.client_tel2",
      "client.client_tel3",
      "client.client_tel_fax",
      "client.client_commentaire",
      "client.client_comptes"
    ]),
    compteDescription() {
      if (this.client_comptes) {
        return this.client_comptes.description;
      }
      return "";
    }
  }
};
</script>
